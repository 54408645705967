import React, { Component } from "react";

export class Fragment extends Component {
  render() {
    return (
      <div>
        Fragment Validator not yet implemented. 
        <br/>
        <br/>
        Github repository{" "}
        <a
          href="https://github.com/jddamore/ccda-fragment-validator/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://github.com/jddamore/ccda-fragment-validator/
        </a>
      </div>
    );
  }
}

export default Fragment;
